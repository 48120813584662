import { QueryClient, QueryClientProvider } from 'react-query';
import { CalendarProvider } from '../lib/calendar';

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: any) => {
  return (
    <CalendarProvider>
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
      </QueryClientProvider>
    </CalendarProvider>
  );
};

export default MyApp;
